import { useAuth0 } from "@auth0/auth0-react";

function LogOut(){

    const { logout } = useAuth0();

    logout({ }).then(() => {
		// eslint-disable-next-line no-undef
		Office.context.ui.messageParent(JSON.stringify({}), { targetOrigin: "*" });
    });

    return <div>Logging Out...</div>;
}

export default LogOut;