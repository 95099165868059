import {useAuth0} from "@auth0/auth0-react";
import LoggedIn from "./LoggedIn";
import defaultAuthParams from "../constants/defaultAuthorizationParams";


function LogInWrapper() {

	
	const {
		isAuthenticated,
		isLoading,
		loginWithRedirect,
		error
	} = useAuth0();

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (!isLoading) {
		if (!isAuthenticated) {
			return loginWithRedirect({
				authorizationParams: defaultAuthParams
			}).then(() =>{
				return <LoggedIn/>;
			});
			}
			else {
				return <LoggedIn/>;
			}
	} else{
		return <>Loading</>
	}
}

export default LogInWrapper;