import './App.css';
import LogInWrapper from "./components/LogInWrapper";
import LogOut from "./components/LogOut";

function App() {

	const searchParams = new URLSearchParams(document.location.search)
	if (searchParams.get("action") === 'logout') {
		return <LogOut />
	}else{
		return <LogInWrapper/>;
	}

}

export default App;
